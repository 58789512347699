import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import 'swiper/dist/css/swiper.min.css'



// import '@vant/touch-emulator'

// vconsole
// import vconsole from '@/assets/js/vconsole'

// element ui
import {
  Popover,
  Icon,
  Loading,
  MessageBox,
  Message,
  Popconfirm,
  Tooltip,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Button,
  Pagination,
  Image,
  Carousel,
  CarouselItem,
  Input,
  Upload,
  Slider,
  RadioGroup,
  RadioButton,
  Select,
  Form,
  FormItem,
  Option
} from 'element-ui'

Vue.use(Popover)
Vue.use(Popconfirm)
Vue.use(Icon)
Vue.use(Tooltip)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Button)
Vue.use(Pagination)
Vue.use(Image)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Input)
Vue.use(Upload)
Vue.use(Slider)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Select)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Option)

Vue.use(Loading.directive)
Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
Vue.prototype.$moment = moment

// vant
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

Vue.use(aiPublicHeader)

// 请求置全局 方便使用
import { request } from '@/util/req'
Vue.prototype.$request = request

// 公共样式
import './assets/css/bass.css'
import './assets/css/app.less'
import './assets/css/app-vars.less'

// 公共组件
// 复制
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

import Foot from '@/components/Foot.vue'
import aiPublicHeader from '@/components/ai-public-header'
Vue.component('Foot', Foot)
Vue.component('aiPublicHeader', aiPublicHeader)

// bus
import bus from './util/bus.js'
Vue.prototype.$bus = bus

Vue.config.productionTip = false

export const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

