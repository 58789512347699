<template>
  <div class="layout">
      <common-nav></common-nav>
      <div class="home_wrap pdtop">
          <div class="content_wrap">
              <div class="content_wrap_inner">
                  <!-- pc端左侧 -->
                  <div v-show="showLeft" class="main_left">
                      <div>
                          <van-search
                                  v-model="searchval"
                                  placeholder="搜索应用"
                                  clear-trigger="always"
                                  :clearable="false"
                                  @input="searchName"
                          />
                      </div>
                      <div
                              @click="choosetab({ category_id: '0' })"
                              :class="[
            'mb10',
            'all_main',
            tabId === '0' ? 'all_main_selected' : '',
          ]"
                      >
                          <img
                                  v-if="tabId === '0'"
                                  class="app_icon"
                                  :src="require('@/assets/imgs/all_checked.png')"
                          />
                          <img
                                  v-else
                                  class="app_icon"
                                  :src="require('@/assets/imgs/all_unchecked.png')"
                          />
                          <span>全部应用</span>
                      </div>
                      <div
                              class="tab_warp"
                              v-for="(item, index) in tabs"
                              @click="choosetab(item)"
                              :class="[
                                tabId === item.category_id ? 'main_selected' : '',
                                index !== tabs.length - 1 ? 'mb10' : '',
                                ['10000', '10001'].includes(item.category_id)?'bGray':''
                              ]"
                              :key="index"
                      >
                          <img v-if="tabId === item.category_id" class="app_icon" :src="item.category_icon_checked" />
                          <img v-else class="app_icon" :src="item.category_icon" />
                          <span>{{ item.category_name }}</span>
                          <span class="see_text" v-if="['10000', '10001'].includes(item.category_id)">2.0版见</span>
                      </div>
                  </div>
                  <!-- pc端右侧  -->
                  <div class="main_right">
                      <router-view></router-view>
                      <foot v-show="showFooter"></foot>
                      <!-- <Service v-show="showService"></Service> -->
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<!-- loading  输入框回车问题  屏幕自动定位到最下方问题 -->
<script>
import { mapState } from 'vuex'
import { debounce } from '@/util/common'
import CommonNav from "@/components/CommonNav.vue";
import Service from '@/components/Service.vue'
import Bus from '@/util/bus'


export default {
components: {CommonNav, Service},
data() {
  return {
    searchval: '',
    tabs: [],
    openFirstNotice: false,
    question:'',
    chatParams: {},
  }
},
computed: {
  ...mapState({
    useInfo: (state) => state.useInfo,
  }),
  showLeft() {
    return typeof this.$route.meta.showLeft === 'undefined' || this.$route.meta.showLeft
  },
  showFooter() {
      return typeof this.$route.meta.showFooter === 'undefined' || this.$route.meta.showFooter
  },
  showService() {
      return this.$route.meta.showService
  },
  tabId() {
    return this.$route.query.categoryId || '0'
  },
},
  created() {
    this.getlist()
    this.getChatParams()
},
methods: {
  // 搜索
  searchName:  debounce( function() {
    Bus.$emit('setSearchAppText', this.searchval)
  }),
  save: debounce(function () {
    if (!this.question) {
      this.$message({
        message: '请输入您的指令',
        type: 'warning',
      })
      return
    }
    this.$store.commit('setMainAppId', '48')
    // 存从弹窗进入标识
    this.$store.commit('setIsDialogQuestion', this.question)

    this.$emit('input', false)
    this.$router.push({ path: '/chat' })
  }),
  cleardata() {
    this.$store.commit('setIsDialogQuestion', '')
    this.$store.commit('setAppId', '')
    this.$store.commit('setNowChat', '')
    this.$store.commit('setMainAppId', '')
    this.$store.commit('clearAppChatList')
    this.$store.commit('setIsDialogQuestion', '')
    this.$store.commit('setChatDetailId', '')
  },
  getChatParams () {
    let params = {
      member_token: this.useInfo && this.useInfo.member_token,
      catetory: 0,
      page: 1,
      page_size: 100,
    }
    this.$request('index/applist', params, 'get').then((rs) => {
      if (rs && rs.code === 200) {
        if(rs && rs.data.list){
          this.chatParams = rs.data.list.filter(v => v.app_id ==='48')[0]
        }
      }
    })
  },
  // 获取所有列表
   getlist() {
    return this.$request(
      'index/index',
      { member_token: (this.useInfo && this.useInfo.member_token) || '' },
      'get'
    ).then((rs) => {
      if (rs && rs.code === 200) {
        const disableArr = [
          {
            category_id: '10009',
            category_name: 'AI音频',
            category_icon: require('@/assets/img/audio.png'),
              category_icon_checked: require('@/assets/img/audio_active.png'),
              category_type: '4',
          },
          // {
          //   category_id: '10001',
          //   category_name: 'AI数字人',
          //   category_icon: require('@/assets/img/num.png'),
          // },
          // {
          //   category_id: '10001',
          //   category_name: 'AI产品图',
          //   category_icon: require('@/assets/img/pro.png'),
          // },
          // {
          //   category_id: '10001',
          //   category_name: 'AI-PPT',
          //   category_icon: require('@/assets/img/ppt.png'),
          // },
        ]
        this.tabs = [
          ...rs.data.category_list,
          ...disableArr,
        ]
      }
    })
  },
  // 万能应用
  goChat(item) {
    if (!this.useInfo) {
      this.$message({
        message: '请先登录',
        type: 'warning',
      })
      this.$router.push({
        path: '/login',
      })
      return
    }
      this.$store.commit('setMainAppId', item.app_id)
      this.$store.commit('setAppInfo', {...item})
      this.$store.commit('inputPromptShow', true)
  },
  // 切换类型
  choosetab(v) {
    this.searchval = ''
    // 2.0 暂未开放
    if (['10000', '10001'].includes(v.category_id)) {
      this.$message({
        message: '暂未开放 敬请期待',
        type: 'warning',
      })
      return
    }
    // category_type  根据这个决定跳转什么类型的页面，1生文 就之前正常的跳转  2 生图   3是单个应用，下面有个app_id 就跳这个应用就行了
    if (v.category_type === '1' || v.category_id === '0') {
        this.$router.push({path: `/home?categoryId=${v.category_id}`})
    } else if (v.category_type === '2'){ // ai设计
        this.$router.push(`/ai-design?categoryId=${v.category_id}`)
    } else if (v.category_type === '3'){
      this.goChat(this.chatParams)
    } else if (v.category_type === '4'){
        this.$router.push(`/ai-audio?categoryId=${v.category_id}`)
    }
  },
},
}
</script>

<style lang="less" scoped>
.excellent_title{
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  position: relative;
  top: 20px;
}
.special_excellent{
  height: 0;
  overflow: hidden;
  width: 1000px;
}
/deep/ #certify{
  position: relative;
  width: 1000px;
  margin: 0 auto;
  .swiper-button-prev{
    left: -40px;
    background-image:url('@/assets/img/left_icon.png');
    outline: none;
  }
  .swiper-button-next{
    right: -40px;
    background-image:url('@/assets/img/right_icon.png');
    outline: none;
  }
  .swiper-button-prev,.swiper-button-next{
    width: 30px;
    background-size: contain;
  }
  .swiper-slide{
    // width:276px;
    //   height: 276px;
    width: 300px;
    // width: 400px;
    height: 408px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width:276px;
      height: 276px;
      overflow-clip-margin: content-box;
      overflow: clip;
      border-radius: 10px;
    }
  }
  .swiper-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .swiper-slide {
    // width: 520px;
    // height: 408px;
    // // background: #fff;
    // box-shadow: 0 8px 30px #ddd;
  }
}
/deep/ .excellent{
  margin: 0 100px;
  -webkit-user-select:none;
  user-select:none;
  // outline: none;

}
.el-carousel__container{
  // height: 277px;
}
.el-carousel__item{
  background-position: center center;
  background-size: cover;
  // width: 277px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.setScoll {
  overflow: auto;
}
.home_wrap {
  background-color: #f7f7f7;
  min-height: 100vh;
  overflow: auto;
    box-sizing: border-box;
  .content_wrap {
    box-sizing: border-box;
    width: 100%;
    .content_wrap_inner {
      box-sizing: border-box;
      max-width: @cw;
      width: 100%;
      margin: 0 auto;
      .search_box {
        box-sizing: border-box;
        max-width: 748px;
        margin: 0 auto;
        color: #3D3D3D ;
        /deep/ .van-search {
          width: 100%;
          padding: 0px;
          height: 44px;
          border-radius: 5px;
          background-color: #fff;
        }
        /deep/ .van-field__control {
          color: #3D3D3D ;
        }
        /deep/ .van-search__content {
          background-color: #fff;
          border-radius: 5px;
          padding-left: 8px;
        }
        /deep/ .van-search .van-cell .van-field__left-icon {
          background-color: #fff;
        }
        /deep/ .van-field__left-icon .van-icon,
        .van-field__right-icon .van-icon {
          font-size: 20px;
        }
        /deep/ .van-field__body {
          padding-right: 15px;
          color: #ffffff;
        }

        /deep/ .van-field__clear {
          font-size: 20px;
          cursor: pointer;
        }
        .search_box_inner{
          cursor: pointer;
          background: #fff;
          box-sizing: border-box;
          max-width: 508px;
          // max-width: 748px;
          margin: 0 auto;
          border-radius: 10px;
          min-height: 52px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          display: flex;
          .left{
            display: flex;
            align-items: center;
            justify-content: center;
            .ai{
              color: #3A7CFD ;
              font-size: 16px;
              margin-right: 20px;
              margin-left: 6px;
              width: 48px;
            }
            .des{
              font-size: 14px;
              color: #999;
            }
          }
          .textarea_hander {
            flex: 1;
            width: 100%;
            border-radius: 8px;
            color: #3d3d3d;
            box-sizing: border-box;
            padding: 10px;
            background: transparent;
            outline: none;
            border: 1px solid #cecece;
            box-sizing: border-box;
          }
          img{
            width: 24px;
          }
          .btns{
            width: 60px;
            height: 30px;
            background: linear-gradient(135deg, #416fff 0%, #00ced1 100%);
            border-radius: 6px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
      .tabs {
        box-sizing: border-box;
        margin-left: 10px;
        margin-right: 10px;
        // margin-bottom: 10px;
        font-size: 14px;
        border-radius: 5px;
        color: #babdc7;
        white-space: nowrap;
        overflow-x: scroll;
        list-style: none;
        background-color: #36373d;
        padding: 8px 0px;
        .selected {
          display: inline-block;
          color: #ffffff;
          font-weight: 500;
          background-image: linear-gradient(
                  0deg,
                  #ffffff,
                  #ffffff 50%,
                  transparent 50%
          );
          background-size: 35% 5px;
          background-repeat: no-repeat;
          background-position: bottom;
        }
        li {
          display: inline-block;
          padding: 4px 10px;
          cursor: pointer;
          text-align: center;
        }
        li:not(:first-child) {
          text-align: center;
        }
        li:first-child {
          box-sizing: border-box;
        }
      }

      // pc
      // @media screen and (min-width: 993px) {
      .auto_up {
        background-color: #18181c;
      }
      .search_box {
        padding: 36px 0;
        padding-bottom: 0;
      }
      .list_wrap {
        margin-top: 36px;
        box-sizing: border-box;
        // padding: 10px 0px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill, 150px);
        // grid-gap: 14px;
        column-gap:22px;
        row-gap: 22px;
        min-height: calc(~'100vh - 500px');
        // min-height: calc(~'100vh - 314px');
        .project {
          // background: url('@/assets/imgs/project_kuang.png');
          // background: #00ced1;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          display: flex;
          height: 150px;
          align-items: center;
          color: @f;
          border-radius: 14px;
          cursor: pointer;
          .project_l {
            width: 100%;
            margin-left: 10px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            .projecticon {
              width: 48px;
              height: 48px;
              // margin-bottom: 10px;
              object-fit: cover;
              border-radius: 50%;
            }
            .title{
              width: 100%;
              position: absolute;
              top: 30px;
              margin-top: -20px;
              // position: relative;
              // top: -18px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
            }
            .des{
              // width: 100%;
              // height: 20px;
              overflow: hidden;
              font-size: 10px;
              // white-space: nowrap;
              // margin-top: 10px;
              width: 110px;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 这里是超出几行省略 */
              // margin-right: 90px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              // color:rgba(255, 255, 255, 0.8);
              // margin-top: -10px;
              position: absolute;
              top: 46px;
              font-weight: 200;
            }

            .fire{
              position: absolute;
              font-size: 12px;
              bottom: -7px;
              .van-icon{
                margin-right: 4px;
              }
            }

            .star {
              position: absolute;
              z-index: 1;
              left: 10px;
              top: 10px;
              cursor: pointer;
            }
          }
          .project_r {
            width: 65%;
            height: 120px;
            display: flex;
            align-items: center;
            p {
              font-size: 16px;
              line-height: 25px;
              margin-bottom: 3px;
              // white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
              overflow: hidden;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              max-width: 140px;
            }
            .project_btn {
              cursor: pointer;
              width: 64px;
              height: 25px;
              // background-color: @c;
              background-image: linear-gradient(
                      to bottom right,
                      #416fff,
                      #00ced1
              );
              color: @f;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              border-radius: 5px;
              font-weight: bold;
              // margin-top: 13px;
            }
          }
        }
        .special-project{
          height: 228px;
          border-radius: 0px;
        }
        .project_botton{
          background: #fff;
          border-radius: 0px 0px 12px 12px;
          padding: 20px;
          padding-bottom: 10px;
          padding-top: 10px;
          font-size: 16px;
          position: relative;
          .des{
            font-size: 8px;
            color: #3D3D3D ;
            margin-top: 10px;
          }
          .isNext{
            background: @themecolor;
            border-radius: 16px;
            height: 20px;
            line-height: 20px;
            width: 100px;
            position: absolute;
            color: #fff;
            font-size: 12px;
            right: 5px;
            text-align: center;
            top: -25px;
          }
        }
        .Bgray{
          background: rgba(228, 228, 228, 1);
        }
      }
      .special_list_warp{
        // grid-template-rows: repeat(auto-fill, 338px);
        // min-height: 400px!important;
        // column-gap: 39px!important;
        // row-gap: 22px!important;
        grid-template-rows: auto !important;;
      }
    }

    // 手机
    // @media screen and (min-width: 0px) and (max-width: 992px) {
    //   .auto_up {
    //     background-color: #18181c;
    //   }
    //   .tabbox {
    //     padding-bottom: 10px;
    //   }
    //   .search_box {
    //     padding: 10px;
    //   }

    //   .list_wrap {
    //     box-sizing: border-box;
    //     padding: 0px 10px 10px 10px;
    //     display: grid;
    //     grid-template-columns: repeat(2, 1fr);
    //     grid-gap: 10px;
    //     grid-template-rows: repeat(auto-fill, 100px);
    //     .project {
    //       color: @f;
    //       cursor: pointer;
    //       background: url('@/assets/imgs/project_kuang.png');
    //       background-size: 100% 100%;
    //       background-repeat: no-repeat;
    //       display: flex;
    //       height: 100px;
    //       align-items: center;
    //       .project_l {
    //         width: 100%;
    //         margin-right: 4%;
    //         height: 100%;
    //         display: flex;
    //         align-items: center;
    //         justify-content: flex-end;
    //         position: relative;
    //         .projecticon {
    //           width: 48px;
    //           height: 48px;
    //           // margin-bottom: 10px;
    //           object-fit: cover;
    //           border-radius: 50%;
    //         }
    //       }
    //       .project_r {
    //         width: 65%;
    //         height: 100%;
    //         display: flex;
    //         align-items: center;
    //         p {
    //           font-size: 14px;
    //           line-height: 25px;
    //           margin-bottom: 3px;
    //           // white-space: nowrap;
    //           // overflow: hidden;
    //           // text-overflow: ellipsis;
    //           overflow: hidden;
    //           -webkit-line-clamp: 2;
    //           text-overflow: ellipsis;
    //           display: -webkit-box;
    //           -webkit-box-orient: vertical;
    //           max-width: 100px;
    //         }
    //         .project_btn {
    //           cursor: pointer;
    //           width: 64px;
    //           height: 25px;
    //           // background-color: @c;
    //           background-image: linear-gradient(
    //             to bottom right,
    //             #416fff,
    //             #00ced1
    //           );
    //           color: @f;
    //           display: flex;
    //           align-items: center;
    //           justify-content: center;
    //           font-size: 14px;
    //           border-radius: 5px;
    //           font-weight: bold;
    //           // margin-top: 13px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
// }
// pc
// @media screen and (min-width: 993px) {
.pdtop {
  padding-top: 65px;
}
.banner {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  height: auto;
  background: url('@/assets/imgs/banner_wrap_new.png');
  background-size: cover;
  .banner_inner {
    width: 100%;
    max-width: 1270px;
    margin: 0 auto;
    .banner_text {
      width: 70%;
      max-width: 449px;
      margin-left: 40px;
    }
  }
}
.content_wrap_inner {
  display: flex;
  box-sizing: border-box;
  // padding-top: 5px;
  .main_left {
    width: 186px;
    min-height: 100vh;
    // width: 210px;
    // height: fit-content;
    margin-right: 15px;
    // border-radius: 12px;
    background-color: #fff;
    box-sizing: border-box;
    // padding: 0px 12px 12px;
    // margin-top: 10px;
    .all_main {
      height: 34px;
      // border-radius: 12px 12px 0px 0px !important;
    }
    .all_main_selected {
      background-color: #DFECFA ;
      color: @themecolor;
    }
    .main_selected {
      background-color: #DFECFA ;
      color: @themecolor;
    }
    .tab_warp{
      position: relative;
      .see_text{
        position: absolute;
        right: -10px;
        bottom: 0px;
        font-size: 16px;
        color: @themecolor;
        font-weight: 400;
        transform: scale(0.5);
        // -o-transform:scale(0.5);
      }
    }
    .bGray{
      background:  #F6F6F6!important;
    }
    >div:first-child{
      margin-top: 36px;
      height: 42px;
    }
    > div {
      box-sizing: border-box;
      // width: 100%;
      height: 34px;
      margin: 0 20px;
      background-color: #fff;
      border-radius: 0px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #333333 ;
      cursor: pointer;
      position: relative;
      margin-bottom: 20px;
      .van-search{
        padding:0;
        border: 1px solid #C5C5C5 ;
        border-radius: 8px ;
        background-color: #fff;
        .van-search__content{
          padding-left: 9px;
          background-color: transparent;
        }
        /deep/ .van-cell{
          // padding: 10px 16px;
          padding: 3px 8px 3px 0;
          .van-field__left-icon {
            .van-icon{
              font-size: 20px;
            }
          }
          .van-field__control{
            font-size: 12px;
          }
        }
      }
      .see {
        width: 76px;
        position: absolute;
        z-index: 1;
        right: 0px;
        top: 0px;
      }
      .app_icon {
        width: 20px;
        margin-right: 8px;
        margin-left: 10px;
      }
    }
  }
  .main_right {
    flex: 1;
    min-height: 200px;
    position: relative;
    .banner {
      margin-top: 36px;
      width: 100%;
      padding-top: 40px;
      padding-bottom: 40px;
      height: auto;
      background: url('@/assets/imgs/banner_wrap_new.png');
      background-size: cover;
      border-radius: 10px;
      .banner_inner {
        width: 100%;
        max-width: 1270px;
        margin: 0 auto;
        .banner_text {
          width: 70%;
          max-width: 449px;
          margin-left: 40px;
        }
      }
    }
  }
}
// }
// 手机
// @media screen and (min-width: 0px) and (max-width: 992px) {
//   .pdtop {
//     padding-top: 50px;
//   }

//   .banner {
//     width: 100%;

//     height: auto;
//     background: url('@/assets/imgs/banner_wrap_new.png');
//     // background: url('@/assets/imgs/banner_wrap_ih.png');
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center center;
//     .banner_inner {
//       width: 100%;
//       max-width: 1270px;
//       // height: 100px;
//       padding-top: 30px;
//       padding-bottom: 30px;
//       margin: 0 auto;
//       .banner_text {
//         width: 60%;
//         max-width: 449px;
//         margin-left: 20px;
//       }
//     }
//   }
// }
</style>

