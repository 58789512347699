<template>
  <div class="head_wrap navh">
    <div class="head_inner">
      <div class="head_l">
        <!-- <img class="fengcheimg" :src="require('@/assets/img/1.gif')" @click="backhome1"/> -->
        <img  src="@/assets/imgs/font_logo_new.png" class="logo ml2" @click="backhome1" />
        <!-- <img  src="@/assets/imgs/font_logo_new.png" class="logo ml2" @click="backhome" /> -->
        <div class="ispc">
          <span
            :class="['homePage', chooseOne === '/home' || chooseOne === '/ai-design' ? 'selecedTab' : '']"
            @click="backhome1"
            >首页</span
          >
        </div>
        <div v-if="useInfo" class="ispc">
          <div
            :class="[
              'head_r_teach1',
              'gcenter',
              'mr6',
              chooseOne === '/chathisory' ? 'selecedTab' : '',
            ]"
            @click="myset(2)"
          >
            <!-- <img src="@/assets/imgs/li_shi.png" /> -->
            历史会话
          </div>
        </div>
        <!-- <div v-if="useInfo" class="ispc">
          <div
            :class="[
              'head_r_teach1',
              'gcenter',
              'mr6',
               'cGray',
            ]"
            @click="canotClick"
          >
            AI图库<span>(2.0版见)</span>
          </div>
        </div> -->
        <!-- <div v-if="useInfo" class="ispc">
          <div
            :class="[
              'head_r_teach1',
              'gcenter',
              'mr6',
              'charge',
              chooseOne === '/createnum' ? 'selecedTab' : '',
            ]"
            @click="myset(5)"
          >
            充值
          </div>
        </div> -->
        <!-- <div v-if="useInfo" class="ispc">
          <div
            :class="[
              'head_r_teach1',
              'gcenter',
              'mr6',
              chooseOne === '/share' ? 'selecedTab' : '',
            ]"
            @click="myset(6)"
          >
            分享好友
          </div>
        </div> -->
      </div>
      <div class="head_r">
        <div class="feng_che_num guide"  @click="goGuide">
          <!-- <img src="@/assets/guideimgs/guidericon.png" alt=""> -->
          <span>新手指南</span>
        </div>
        <!-- 是否年卡会员 1是 2否is_year_vip -->
        <!-- <div class="feng_che_num">
          我的小风车：
          <span class="vip" v-if="useInfo.is_year_vip && useInfo.is_year_vip === '1'"><img src="@/assets/imgs/vip.png" @click="openInstruction"/></span>
          <span v-else>{{questionNumber}}个</span>
          <img src="@/assets/img/say.png" @click="openInstruction"/>
        </div> -->
        <div class="ispc noticewrap">
          <div class="head_r_teach gcenter mr10" @click="myset(3)">
            <img src="@/assets/imgs/tong_zhi_new.png" />
            <span class="noticeDian" v-if="showdian"></span>
          </div>
        </div>
        <div
        class="user-warp"
          v-if="useInfo"
          @mouseenter="isPc ? close(true) : close(false)"
          @click.stop="close(true)"
        >
          <template v-if="loginType === 'wx'">
            <!-- 0无，2月,3季,4年 -->
            <img
            v-if="useInfo.vip_type ==='2'"
              class="mr10 vip_avatar"
              src="@/assets/img/yueka.png"
            />
            <img
            v-else-if="useInfo.vip_type ==='3'"
              class=" mr10 vip_avatar"
              src="@/assets/img/jika.png"
            />
            <img
            v-else-if="useInfo.vip_type ==='4'"
              class=" mr10 vip_avatar"
              src="@/assets/img/nianka.png"
            />
            <img
              :class="useInfo.vip_type ==='2' || useInfo.vip_type ==='3' || useInfo.vip_type ==='4'?'avatar':''"
              class="empty_person mr10"
              :src="useInfo.headimgurl"
              v-if="useInfo.headimgurl"
            />
            <span class="person_name mr5" v-else>{{ useInfo.nickname || useInfo.name}}</span>
          </template>
          <template v-else>
            <span class="person_name mr5">{{
              (useInfo && useInfo.nickname) || useInfo.name
            }}</span>
          </template>
          <i class="el-icon-arrow-down mr10"></i>
        </div>
        <div @click="gologin" class="loginbtn" v-else>登录/注册</div>
        <div
          class="optionlist"
          v-if="setLogin"
          @mouseleave.stop="isPc ? close(false) : ''"
        >
          <div class="empty_option" @click.stop="close(false)"></div>
          <div class="options_wrap">
            <template v-if="!isPc">
              <div class="option_item" @click.stop="backhome">首页</div>
              <div class="line"></div>
              <div class="option_item" v-if="useInfo" @click.stop="myset(2)">
                <!-- 原 会话列表 功能 点开列表功能-->
                历史会话
              </div>
              <div class="line" v-if="useInfo"></div>
              <div class="option_item" @click.stop="myset(5)">创作次数</div>
              <div class="line"></div>
              <div class="option_item" @click.stop="myset(6)">分享好友</div>
              <div class="line"></div>
              <div class="option_item" @click.stop="myset(3)">通知</div>
              <div class="line"></div>
            </template>
            <!-- todo icon -->
            <!-- <div class="option_item special" @click="goRebate">
              <img src="@/assets/imgs/share_icon.png" />邀请返利
            </div> -->
            <!-- <div class="line"></div> -->
            <div class="option_item special" @click.stop="myset(0)">
              <img src="@/assets/imgs/login_out.png" />退出登录
            </div>
            <div class="line"></div>
            <!-- is_bind_status    //0 无动作，1去绑定手机号 2去绑定微信 -->
            <!-- <div v-if="useInfo.is_bind_status !=='0'" class="option_item special" @click="gobindAccount">
              <img src="@/assets/imgs/account.png" />账户绑定
            </div> -->
          </div>
        </div>
        <!-- <div v-if="useInfo" class="ispc share">
          <div
            :class="[
              'head_r_teach1',
              'gcenter',
              'mr6',
              'share_friend',
              chooseOne === '/share' ? '' : '',
            ]"
            @click="openShare"
          >
            分享好友
          </div>
        </div> -->
      </div>
      <!--   @mouseleave.stop="openNoticeList=false"-->
      <div class="notic_list_wrap" v-if="openNoticeList">
        <div class="notic_list_head">
          消息通知
          <span class="close" @click="clearNoticeList">
            <img src="@/assets/imgs/clear_m.png" />
          </span>
          <span class="close closeNotice" @click="closeNotice">
            <i class="el-icon-close"></i>
          </span>
        </div>
        <div class="notic_list_content">
          <template v-if="noticeList.length">
            <div
              v-for="(item, index) in noticeList"
              :key="index"
              @click="chooseNotice(item)"
            >
              <div class="text1">
                <!-- <span
                  :class="['dian', item.notice_status === '1' ? 'blue' : '']"
                ></span> -->
                <span>{{ item.notice_title }}</span>
              </div>
              <div class="text2">
                <span :class="['circle', item.notice_status === '2' ? 'blue' : '']"></span>
                <span >
                  {{ item.notice_create_time }}
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <p class="emptynotice">暂无通知</p>
          </template>
        </div>
        <!-- <div class="notic_list_more" @click="seeMore">查看更多></div> -->
      </div>
    </div>
    <!-- tips 每日登录 就显示一次 -->
    <!-- <div class="tips" v-if="showTips">
      今日登录福利：小风车数量
      <span class="tipsTime">+2次</span>
    </div> -->
    <!-- 通知详情 -->
    <notice-detail
      v-model="openNoticeDetail"
      :noticeDetail="chooseNoticeDetail"
      v-if="openNoticeDetail"
    ></notice-detail>
    <first-notice
      v-model="openFirstNotice"
      v-if="openFirstNotice"
    ></first-notice>
    <dialog-share
    v-model="shareShow"
      v-if="shareShow">
    </dialog-share>
    <!-- 绑定弹框 -->
    <van-popup
      v-model="showBindAccount"
      class="pup"
      round
      :close-on-click-overlay="false"
      :closeable="true"
      @click-close-icon="handleClose"
      :style="{ height: '400px', width: '380px' }"
    >
      <p class="bind_title">账户绑定</p>
      <StartLoginIh
      openType="message"
      dialogType="bind"
      ></StartLoginIh>
      <StartLoginwx
      v-if="loginType === 'message'"
      openType="wx"
      dialogType="bind"
      ></StartLoginwx>
    </van-popup>
    <DialogRebate
    v-model="showRebate"
    v-if="showRebate"
    @update:showCash="updateShowCash"
    ></DialogRebate>
    <DialogCash
    v-model="showCash"
    v-if="showCash"
    @update:showCash="updateShowCash"
    ></DialogCash>
    <DialogInstruction
    v-model="showInstruction"
    v-if="showInstruction">
    </DialogInstruction>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import NoticeDetail from '@/components/NoticeDetail.vue'
import FirstNotice from '@/components/FirstNotice.vue'
import StartLoginIh from '@/components/StartLoginIh.vue'
import StartLoginwx from '@/components/StartLoginwx.vue'
import DialogShare from '@/views/share.vue'
import DialogRebate from '@/components/DialogRebate.vue'
import DialogCash from '@/components/DialogCash.vue'
import DialogInstruction from '@/components/DialogInstruction.vue'
import config from '@/config'

export default {
  components: { NoticeDetail, FirstNotice,DialogShare, StartLoginIh, StartLoginwx, DialogRebate, DialogInstruction, DialogCash },
  data() {
    return {
      showPopover: false,
      setLogin: false,
      chooseOne: '/home',
      showTips: false,
      openNoticeList: false, // 通知列表
      openNoticeDetail: '', // 通知详情 开关
      chooseNoticeDetail: '', // 选中的通知详情
      noticeList: [],
      showdian: false,
      openFirstNotice: false,
      hasData: true, // 列表是否为空
      shareShow: false,
      showBindAccount: false,
      showRebate: false,
      showInstruction: false,
      fengcheNum: '',
      showCash: false // todo 为false
    }
  },
  watch: {
    openNoticeDetail: {
      handler(v) {
        this.getNoticeList(500)
      },
    },
    $route: {
      handler(v) {
        // 保持刷新页面nav选中状态
        this.chooseOne = v.path
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
      loginType: (state) => state.loginType,
      questionNumber: (state) => state.questionNumber,
    }),
    // 限制 聊天页面 和 移动端显示
    inChat() {
      return ['/chathisory', '/chat'].includes(this.$route.path)
    },
    isPc() {
      return document.body.offsetWidth >= 993
    },
    isMobileDevice() {
      const isMobileDevice = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
        navigator.userAgent
      )
      return isMobileDevice
    },
  },
  mounted() {
    this.$bus.$off('showDayTips')
    this.$bus.$on('showDayTips', (v) => {
      this.showTips = v
    })
    this.getNoticeList(500)
    this.getNumber()
    this.$bus.$off('update:questionNumber')
    this.$bus.$on('update:questionNumber', () => {
      // console.log('收到通知刷新小风车')
      this.getNumber()
    })
    this.$bus.$off('close:showBindAccount')
    this.$bus.$on('close:showBindAccount', (v) => {
      this.handleClose()
    })
  },
  methods: {
    goGuide (){
      let url = `${config.webHost}#/guide`
      window.open(url, '_blank')
    },
    updateShowCash (val) {
      this.showCash = val
    },
    canotClick (){},
    // 小风车使用弹框
    openInstruction (){
      this.showInstruction = true
    },
    // 邀请返利
    goRebate () {
      this.showRebate = true
    },
    getNumber() {
      this.$request(
        'index/user-info',
        { member_token: (this.useInfo && this.useInfo.member_token) || '' },
        'get'
      ).then((rs) => {
        if (rs && rs.code === 200) {
          this.fengcheNum = rs.data && rs.data.question_number
          this.$store.commit('setQuestionNumber', this.fengcheNum)
          this.$store.commit('setUseInfoIsYearVip', rs.data.is_year_vip)
          // vip_type字段，0无，2月,3季,4年
          this.$store.commit('setUseInfoVipType', rs.data.vip_type)
          console.log(this.useInfo, 'useInfo')
        }
      })
    },
    handleClose (){
      this.showBindAccount = false
    },
    gobindAccount () {
      this.showBindAccount = true
      console.log(this.loginType, 'gobindAccount')
      // message 手机号登录  wx 微信登录
    },
    // 会话记录
    getChatList() {
      return new Promise((resolve) => {
        let params = {
          member_token: this.useInfo.member_token,
        }
        this.$request('app/enter', params, 'get').then((rs) => {
          if (
            rs &&
            rs.code === 200 &&
            rs.data.chat_list &&
            rs.data.chat_list.list &&
            rs.data.chat_list.list.length
          ) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },
    clearNoticeList () {
      let params = {
        member_token: this.useInfo && this.useInfo.member_token,
      }
      this.$request('notice/delete', params, 'get').then((rs) => {
        if (rs && rs.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success',
          })
        }
      })
    },
    // 消息列表
    getNoticeList(page_size = 10) {
      let params = {
        member_token: this.useInfo && this.useInfo.member_token,
        page: 1,
        page_size: page_size,
      }
      this.$request('notice/list', params, 'get').then((rs) => {
        if (rs && rs.code === 200) {
          this.noticeList = rs.data.list || []
          if (this.noticeList.some((item) => item.notice_status === '1')) {
            this.showdian = true
          } else {
            this.showdian = false
          }
        }
      })
    },
    chooseNotice(item) {
      this.chooseNoticeDetail = item
      this.openNoticeDetail = true
      this.openNoticeList = false
    },
    regisiterNotice() {
      this.openFirstNotice = true
    },
    closeNotice() {
      this.openNoticeList = false
    },
    close(v) {
      if (v) {
        this.openNoticeList = false
      }
      this.setLogin = v
    },
    cleardata() {
      this.$store.commit('setIsDialogQuestion', '')
      this.$store.commit('setAppId', '')
      this.$store.commit('setNowChat', '')
      this.$store.commit('setMainAppId', '')
      this.$store.commit('clearAppChatList')
      this.$store.commit('setIsDialogQuestion', '')
      this.$store.commit('setChatDetailId', '')
    },
    async goHistory() {
      let bol = await this.getChatList()
      if (bol) {
        this.cleardata()
        this.chooseOne = '/chathisory'
        this.$router.push({ path: '/chathisory' })
      } else {
        this.$message({
          message: '暂无历史会话',
          type: 'success',
        })
      }
    },
    openShare () {
      this.shareShow = true
      this.openNoticeList = false
      this.close(false)
    },
    myset(type) {
      switch (type) {
        case 0:
          // 退出
          this.cleardata()
          this.$message({
            message: '退出成功',
            type: 'success',
          })
          this.$store.commit('setUseInfo', '')
          this.$router.push({ path: '/login' })
          setTimeout(() => {
            location.reload()
          }, 300)
          break
        case 1:
          break
        case 2:
          // 历史会话
          if (this.inChat && this.isPc) {
            // pc上
            this.$bus.$emit('listen:setdefalut', true)
          } else if (this.inChat && !this.isPc) {
            // 移动端
            this.$bus.$emit('listen:openchat', true)
            this.$bus.$emit('listen:setdefalut', true)
          } else {
            this.goHistory()
          }
          // // 历史会话
          // if (this.inChat && !this.isPc) {
          //   this.$bus.$emit('listen:openchat', true)
          //   this.$bus.$emit('listen:setdefalut', true)
          // } else {
          //   this.goHistory()
          // }

          // this.$bus.$emit('listen:openchat', true)
          // if (this.$route.path === '/chat') {
          //   location.reload()
          // } else {
          //   this.$router.push({ path: '/chat' })
          // }
          break
        case 3:
          // 通知列表暂时注释
          this.openNoticeList = !this.openNoticeList
          this.getNoticeList(500)
          break
        // case 4:
        //   this.$bus.$emit('listen:openchat', true)
        //   break
        case 5:
          this.chooseOne = '/createnum'
          this.$router.push({ path: '/createnum' })
          break
        case 6:
          this.openShare()
          // this.chooseOne = '/share'
          // this.$router.push({ path: '/share' })
          break
      }
      this.close(false)
    },
    backhome1 () {
      if (this.$route.fullPath === '/home'){
        location.reload()
      } else {
        this.backhome()
      }
    },
    backhome() {
      this.chooseOne = '/home'
      this.$router.push({
        path: '/home',
      })
      this.close(false)
    },
    gologin() {
      this.$router.push({
        path: '/login',
      })
    },
  },
}
</script>

<style lang="less" scoped>
@fsize: 16px;
.bind_title{
  border-bottom: 1px solid #ddd;
  margin:0 15px ;
  text-align: center;
  padding: 15px 0;
}
.rotate {
  animation: rotate 2s linear infinite;
  }
  @keyframes rotate {
  0% {
  transform: rotate(0);
  }
  100% {
  transform: rotate(360deg);
  }
}
.emptynotice {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.el-icon-arrow-down {
  color: @b;
  font-weight: bold;
}
.head_r_teach {
  position: relative;
  .noticeDian {
    width: 5px;
    height: 5px;
    background-color: #FD3A3A;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    right: 6px;
    top: 4px;
  }
}

.notic_list_wrap {
  // width: 330px;
  width: 280px;
  height: 300px;
  position: absolute;
  border-radius: 10px;
  right: 13px;
  top: 50px;
  color: #3d3d3d;
  display: grid;
  grid-template-rows: 40px auto;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  .notic_list_head {
    background-color: #fff;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 0 20px;
    font-size: 16px;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    .close {
      position: absolute;
      right: 10px;
      top: 6px;
      img{
        width: 24px;
      }
    }
    .closeNotice{
      position: absolute;
      right: 40px;
      top: 6px;
      margin-top: 5px;
    }
  }
  .notic_list_content {
    background-color: #fff;
    box-sizing: border-box;
    overflow-y: auto;
    font-size: 14px;
    border-radius: 0px 0px 10px 10px;
    > div {
      box-sizing: border-box;
      padding: 12px 0px;
      margin: 0 20px;
      border-bottom: 1px solid #D8D8D8;
      cursor: pointer;
      .text1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
        .dian {
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background-color: #ffffff;
          margin-right: 6px;
          transform: translateY(-3px);
        }
        .blue {
          background-color: @themecolor !important;
        }
      }
      .text2 {
        font-size: 12px;
        box-sizing: border-box;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .circle{
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          // background: red;
          border: 1px solid #FD3A3A;
        }
        .blue {
          background-color: transparent !important;
          border: 1px solid @themecolor;
        }
      }
    }
  }
  // .notic_list_more {
  //   background-color: #36373d;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   border-radius: 0px 0px 10px 10px;
  //   cursor: pointer;
  //   border-top: 1px solid #65656a;
  // }
}
.optionlist {
  position: absolute;
  z-index: 10;
  right: -10px;
  top: 10px;
  width: 148px;
  border-radius: 5px;
  color: #3d3d3d;
  // font-size: @fsize;
  font-size: 14px;
  .options_wrap {
    background-color: #fff;
    height: auto;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    .line {
      width: 80%;
      height: 0.5px;
      margin: 0 auto;
      background-color: #D8D8D8;
    }
    .option_item {
      width: 100%;
      padding: 15px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img{
        width: 20px;
        margin-right: 18px;
      }
    }
    .special.option_item{
      // justify-content: flex-start;
      justify-content: inherit;
      padding: 15px 20px;
    }
  }
  .empty_option {
    width: 100%;
    height: 40px;
  }
}

// pc
// @media screen and (min-width: 993px) {
  .tips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 340px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #33395f;
    border-radius: 5px;
    background: url('@/assets/imgs/tips.png');
    background-size: 100% 100%;
    color: #ffffff;
    .tipsTime {
      font-size: 20px !important;
      font-weight: bold;
      margin-left: 3px;
      background-image: -webkit-linear-gradient(bottom, #46a8fd, #19e0f8);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .navh {
    height: 65px;
  }
  .head_wrap {
    width: 100%;
    position: fixed;
    z-index: 100;
    left: 0px;
    top: 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    // background-color: #282a35;
    background: #FFFFFF;

    .head_inner {
      position: relative;
      width: 100%;
      max-width: @cw;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .selecedTab {
        // padding: 3px 10px;
        color: @themeredcolor;
        border-radius: 0px;
        border-bottom: 2px solid @themeredcolor;;
        // background: linear-gradient(135deg, #416fff 0%, #00ced1 100%);
      }
      .head_l {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .logo {
          width: 160px; // 160
          cursor: pointer;
          margin-right: 3px;
        }
        .homePage {
          // color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          // width: 40px;
          height: 28px;
          margin-right: 6px;
          margin-left: 43px;
        }
        // .fengche{
        //   width:
        // }
        .head_r_teach1 {
          // color: @b;
          cursor: pointer;
          // width: 106px;
          margin-left: 40px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          img {
            width: 18px;
            margin-right: 4px;
          }
        }
        .charge{
          // margin-left: 6px;
        }
        .cGray{
            color: #999;
            span{
              display: inline-block;
              font-size: 16px;
              vertical-align: baseline;
              transform: scale(0.5);
              margin-top: 5px;
              position: relative;
              left: -16px;
            }
          }
        .share_friend{
          font-size: 14px!important;
        }

        .head_r_teach {
          // background-color: #3e404a;
          padding: 5px 10px;
          color: @b;
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: @fsize;
          border-radius: 20px;
          position: relative;
          .noticeDian {
            width: 4px;
            height: 4px;
            background-color: red;
            position: absolute;
            z-index: 1;
            right: 0px;
            top: 0px;
          }
          img {
            width: 18px;
            margin-right: 4px;
          }
        }
      }
      .head_r {
        display: flex;
        align-items: center;
        position: relative;
        .feng_che_num{
          color: #666;
          font-size: 14px;
          margin-right: 30px;
          display: flex;
          align-items: center;
          img{
            width: 18px;
            margin-left: 10px;
            vertical-align: middle;
          }
          .vip{
            img{
              width: 28px;
              margin-left: 0px;
            }
          }
        }
        .guide{
          margin-right: 25px;
          cursor: pointer;
          img{
            margin-right: 10px;
          }
        }
        .share{
          margin-left:20px;
          cursor: pointer;
          .share_friend{
            font-size: 14px;
            color: #3D3D3D;
          }
        }
        .loginbtn {
          background: linear-gradient(135deg, #416fff 0%, #00ced1 100%);
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: @fsize;
          cursor: pointer;
          color: #ffffff;
          padding: 5px 10px;
          margin-right: 10px;
        }
        .head_r_teach {
          background-color: @f;
          padding: 5px 10px;
          color: @b;
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: @fsize;
          border-radius: 20px;
          img {
            width: 18px;
            margin-right: 4px;
          }
        }
        .head_r_login {
          width: 134px;
          height: 40px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          > span {
            display: grid;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          > span:nth-of-type(1) {
            background-color: @c;
            color: #ffffff;
          }
          > span:nth-of-type(2) {
            background-color: @b;
            color: @c;
          }
        }
        .head_r_login2 {
          cursor: pointer;
          width: 67px;
          height: 40px;
          background-color: @b;
          color: @c;
        }
        .ling {
          cursor: pointer;
        }
        .empty_person {
          width: 40px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 50%;
          cursor: pointer;
        }

        .person_name {
          font-size: @fsize;
          color: @b;
          cursor: pointer;
        }
      }
    }
  }
  .user-warp {
      position: relative;
      display: flex;
      align-items: center;
      .vip_avatar{
        width: 40px;
        height: 40px;
        background-color: #ffffff;
        border-radius: 50%;
      }
      .avatar{
        position: absolute;
        left:5px;
        top: 5px;
        width: 30px!important;
        height: 30px!important;
        background-color: #ffffff;
        border-radius: 50%;
      }
    }
// }

// 手机
// @media screen and (min-width: 0px) and (max-width: 992px) {
//   .tips {
//     position: absolute;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 280px;
//     height: 40px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: #33395f;
//     border-radius: 5px;
//     background: url('@/assets/imgs/tips.png');
//     background-size: 100% 100%;
//     color: #ffffff;
//     font-size: 14px;
//     .tipsTime {
//       font-size: 16px !important;
//       font-weight: bold;
//       margin-left: 3px;
//       background-image: -webkit-linear-gradient(bottom, #46a8fd, #19e0f8);
//       -webkit-background-clip: text;
//       -webkit-text-fill-color: transparent;
//     }
//   }
//   .navh {
//     height: 50px;
//   }
//   .optionlist {
//     right: 10px;
//   }
//   .head_wrap {
//     width: 100%;
//     position: fixed;
//     z-index: 100;
//     left: 0px;
//     top: 0px;
//     box-sizing: border-box;
//     display: flex;
//     align-items: center;
//     background-color: #282a35;
//     .head_inner {
//       width: 100%;
//       max-width: @cw;
//       margin: 0 auto;
//       height: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       .head_l {
//         display: flex;
//         align-items: center;
//         .logo {
//           width: 130px;
//           cursor: pointer;
//         }
//         .menu {
//           width: 28px;
//           color: #ffffff;
//           margin-left: 10px;
//         }
//       }
//       .head_r {
//         display: flex;
//         align-items: center;

//         .loginbtn {
//           font-size: 14px !important;
//           background: linear-gradient(135deg, #416fff 0%, #00ced1 100%);
//           border-radius: 12px;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           font-size: @fsize;
//           cursor: pointer;
//           color: #ffffff;
//           padding: 3px 8px;
//           margin-right: 10px;
//         }
//         .head_r_login {
//           width: 134px;
//           height: 25px;
//           display: grid;
//           grid-template-columns: 1fr 1fr;
//           > span {
//             display: grid;
//             align-items: center;
//             justify-content: center;
//             cursor: pointer;
//           }
//           > span:nth-of-type(1) {
//             background-color: @c;
//             color: #ffffff;
//           }
//           > span:nth-of-type(2) {
//             background-color: @f;
//             color: @b;
//           }
//         }

//         .head_r_login2 {
//           cursor: pointer;
//           width: 40px;
//           height: 25px;
//           background-color: @f;
//           color: @b;
//         }
//         .head_r_teach {
//           background-color: @f;
//           padding: 5px 10px;
//           color: @b;
//           cursor: pointer;
//           display: flex;
//           align-items: center;
//           font-size: @fsize;
//           border-radius: 20px;
//           img {
//             width: 18px;
//             margin-right: 4px;
//           }
//         }
//         .ling {
//           width: 13px;
//           cursor: pointer;
//         }
//         .empty_person {
//           width: 34px;
//           height: 34px;
//           background-color: #ffffff;
//           border-radius: 50%;
//           cursor: pointer;
//         }
//         .person_name {
//           font-size: @fsize;
//           color: @b;
//           cursor: pointer;
//         }
//       }
//     }
//   }
// }
</style>

