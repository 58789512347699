<template>
  <div>
    <!-- 提现弹框 -->
    <van-popup
      v-model="showCash"
      class="pup rulePop cashPop"
      round
      :close-on-click-overlay="false"
      :closeable="true"
      :overlay="true"
      @click-close-icon="handleClose"
      :style="{ height: '440px', width: '480px' }"
    >
    <p class="title">
      提现信息
    </p>
    <div class="content">
      <p class="ketixian">
        <span class="money">可提现金额：
        <span class="Cred">¥{{brokerage_fee || 0}}</span></span>
      </p>
      <van-form>
        <van-field
          v-model="cashObj.name"
          name="真实姓名"
          label="真实姓名"
          label-align="left"
          placeholder=""
          autocomplete="off"
          maxlength="15"
        />
        <van-field
          v-model="cashObj.cardNum"
          name="真实卡号"
          label="真实卡号"
          label-align="left"
          placeholder=""
          autocomplete="off"
          maxlength="20"
        />
        <van-field
          v-model="cashObj.bank"
          name="开户行信息"
          label="开户行信息"
          label-align="left"
          placeholder=""
          autocomplete="off"
          maxlength="15"
        /><van-field
          v-model="cashObj.money"
          type="digit"
          name="提现金额"
          label="提现金额"
          label-align="left"
          placeholder=""
          autocomplete="off"
          @blur="handleBlur(cashObj.money)"
        />
        <div class="btn-box">
          <van-button
          class="bgthemecolor"
          round block
          type="info"
          :disabled="!canSubmit"
          @click="submit"
          >提交</van-button>
        </div>
      </van-form>
    </div>
    </van-popup>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      brokerage_fee: 0,
      showCash: true,
      cashObj: {
        name: '',
        cardNum: '',
        bank: '',
        money: ''
      }
    }
  },
  mounted () {
    this.getList()
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
    }),
    canSubmit () {
      return this.brokerage_fee >= 10 && this.cashObj.money >=10
    }
  },
  methods: {
    handleBlur (val) {
      if (!val) return
      if (val > Number(this.brokerage_fee)){
        this.cashObj.money = this.brokerage_fee - this.brokerage_fee % 10
      } else {
        this.cashObj.money = val - val % 10
      }
    },
    submit () {
      if (!this.cashObj.name){
        this.$message({
          message: '请输入真实姓名',
          type: 'warning',
        })
        return
      }
      if (!this.cashObj.cardNum){
        this.$message({
          message: '请输入真实卡号',
          type: 'warning',
        })
        return
      }
      if (!this.cashObj.bank){
        this.$message({
          message: '请输入开户行信息',
          type: 'warning',
        })
        return
      }
      if (!this.cashObj.money){
        this.$message({
          message: '请输入提现金额',
          type: 'warning',
        })
        return
      }
      // 提现接口todo
      this.$request('/withdraw/save', {
        withdraw_real_name: this.cashObj.name,
        withdraw_bank_card_number: this.cashObj.cardNum,
        withdraw_bank_info: this.cashObj.bank,
        withdraw_fee: this.cashObj.money,
        member_token: this.useInfo && this.useInfo.member_token,
      }, 'post').then((rs) => {
        if (rs && rs.code === 200) {
          this.$message({
            message: '提现申请成功',
            type: 'success',
          })
          this.handleClose()
        } else {
          // console.log(rs.message)
        }
      })
    },
    // 获取可以提现的金额
    getList() {
      this.$request('/brokerage/list', {
        page: this.currentPage,
        page_size: this.pageSize,
        member_token: this.useInfo && this.useInfo.member_token,
      }, 'get').then((rs) => {
        if (rs && rs.code === 200) {
          if (rs.data) {
            this.brokerage_fee = rs.data.brokerage_fee
          }
        } else {
          // console.log(rs.message)
        }
      })
    },
    handleClose (){
      this.$emit('update:showCash',false)
    },
  }
}
</script>
<style lang="less" scoped>
.rulePop{
  .title {
    font-size: 20px;
    width: 92%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
  }
}
.cashPop{
  font-size: 18px;
  .content{
    padding-top: 10px;
    margin-top: 65px;
    overflow: auto;
    box-sizing: border-box;
    .ketixian{
      margin-bottom: 10px;
      font-size: 18px;
      color: #3D3D3D;
    }
    p{
      span {
        font-size: 18px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .Cred {
        color: #FD3A3A;
      }
    }
    .van-form{
      margin: 0 50px;
      .van-cell{
        background: #F3F3F3;
        padding: 10px 0px;
        margin-bottom: 20px;
        border-radius: 8px;
        padding-left: 14px;
      }
      .van-cell::after{
        border-bottom: 1px solid transparent;
      }
    }
    .btn-box{
      display: flex;
      align-items: center;
      justify-content: center;
      .van-button {
        width: 115px;
        height: 30px;
        font-size: 14px;
      }
    }
  }
}
</style>