<template>
  <div class="dialog-instruction">
     <van-popup
      v-model="diaValue"
      class="pup"
      round
      :close-on-click-overlay="true"
      :closeable="true"
      @click-overlay="close"
      @click-close-icon="close"
      :style="{ height: '450px', width: '700px' }"
    >
      <p class="title">小风车消耗说明</p>
      <div class="content">
        <div>
          <p class="special"><span>·</span>怎么定义完成一次消耗？</p>
          <p>每当您完成“输入-收到回答”的1次对话时，视为完成1次消耗~</p>
          <p class="special"><span>·</span>每个工具消耗的小风车数量是一样的吗？</p>
          <p>不是喔！根据您使用的工具类型不同，消耗小风车的数量也不同喔~</p>
          <p class="special"><span>·</span>如何免费获取小风车？</p>
          <p>免费获得小风车的途径有3种：</p>
          <p>1.首次注册免费获得20个小风车；</p>
          <p>2.每日登录免费获得2个小风车；</p>
          <p>3.邀请好友且好友注册成功后，邀请发出人免费获得10个小风车~</p>
          <p class="special"><span>·</span>小风车数量不足时如何购买？</p>
          <p>您可以根据需要，在“创作次数”页面选择相应的包月/包季度/包年会员来购买小风车，也可无限次购买加油包购买小风车喔~</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      default: false,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
    }),
    diaValue: {
      get() {
        return this.value
      },
      set() {},
    },
  },
  created () {
  },
  methods: {
    getList() {
      this.$request('/brokerage/list', {
        page: this.currentPage,
        page_size: this.pageSize,
        member_token: this.useInfo && this.useInfo.member_token,
      }).then((rs) => {
        if (rs && rs.code === 200) {
          if (rs.data) {
            this.tableData = rs.data.list
            this.totalcount = rs.data.totalcount
            this.brokerage_fee = rs.data.brokerage_fee
            this.brokerage_ratio = rs.data.brokerage_ratio
          }
        } else {
          // console.log(rs.message)
        }
      })
    },
    close() {
      this.$emit('input', false)
    },
  }
}
</script>
<style lang="less" scoped>
.dialog-instruction{
  .title {
    font-size: 20px;
    width: 92%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    margin: 0 20px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
  }
  .content{
    padding: 0 40px;
    margin-top: 60px;
    height: 380px;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    p{
      margin-bottom: 10px;
      font-size: 16px;
      margin-left: 16px;
      span{
        font-size: 30px;
        margin: 0 4px;
        vertical-align: sub;
      }
    }
    .special{
      margin-left: 0;
    }
  }
}
</style>