<template>
  <div class="pup">
    <van-popup
      v-model="diaValue"
      class="pup"
      round
      :close-on-click-overlay="true"
      :closeable="true"
      @click-overlay="close"
      @click-close-icon="close"
      :style="{ height: '440px', width: setwidth }"
    >
      <p class="title">{{ obj.notice_title || '通知' }}</p>
      <div class="prompt_wrap">
        <!-- <pre class="conntent">{{ obj.notice_content }}</pre> -->
        <MDPreview class="conntent" :plainText="obj.notice_content"></MDPreview>
        <div class="btns bglinearthemecolor" @click="close">关闭</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MDPreview from '@/components/MDPreview.vue'

export default {
  components: { MDPreview },
  props: {
    value: {
      default: false,
    },
    noticeDetail: {
      typeof: Object,
    },
  },
  data() {
    return {
      obj: '',
    }
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
    }),
    diaValue: {
      get() {
        return this.value
      },
      set() {},
    },
    // 不支持响应式  得刷新页面生效
    setwidth() {
      if (document.body.offsetWidth >= 992) {
        return '500px'
      } else {
        return '330px'
      }
    },
  },
  created() {
    if (this.noticeDetail) {
      this.getDetail()
    }
  },
  methods: {
    // 详情
    getDetail() {
      let params = {
        notice_id: this.noticeDetail.notice_id,
        member_token: this.useInfo && this.useInfo.member_token,
      }
      this.$request('notice/detail', params, 'get').then((rs) => {
        if (rs && rs.code === 200) {
          this.obj = rs.data
        }
      })
    },
    close() {
      this.$emit('input', false)
      this.$emit('refreshList')
    },
  },
}
</script>

<style lang="less" scoped>
.pup {
  padding: 0 20px;
  .title {
    width: 90%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #d8d8d8;
  }
  /deep/ .van-popup {
    background-color: #fff;
  }
  .prompt_wrap {
    width: 100%;
    height: 380px;
    box-sizing: border-box;
    margin-top: 50px;
    padding: 30px 15px 0px;
    box-sizing: border-box;
    position: relative;
    color: #3d3d3d;
    font-size: 14px;
    /deep/ .markdown-body {
      background-color: #fff;
      color: #3d3d3d;
    }
    .conntent {
      height: 260px;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    .btns {
      color: #fff;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      cursor: pointer;
      width: 260px;
      height: 40px;
      // background: linear-gradient(135deg, #416fff 0%, #00ced1 100%);
      border-radius: 12px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  // pc
  @media screen and (min-width: 993px) {
    .title {
      font-size: 20px;
    }
    .btns {
      margin-top: 30px !important;
    }
  }

  // 手机
  @media screen and (min-width: 0px) and (max-width: 992px) {
    .prompt_wrap {
      padding-top: 10px;
    }
    .conntent {
      margin-top: 10px;
    }
    .title {
      font-size: 20px;
    }
    .btns {
      margin-top: 15px !important;
    }
  }
}
</style>

