<template>
  <van-popup
      v-model="showTipsDialog"
      class="pup"
      round
      :close-on-click-overlay="false"
      :closeable="false"
      @click-close-icon="handleClose"
      :style="{ height: '440px', width: '300px' }"
    >
    <p class="title">通知</p>
    <div class="content">
      <p>
        尊敬的用户，您好！
      </p>
      <p>感谢您对我们产品的关注与支持！为了给您提供更优质、更便捷的服务，我们建议您使用PC端访问我们的网页ai.guoquan.cn，获得最佳的使用体验。</p>
      <p>在此期间，如果您有任何问题或建议，欢迎随时联系我们。我们将竭诚为您提供帮助，并不断改进我们的服务。</p>
      <p>感谢您的理解与支持，祝您使用愉快！</p>
    </div>
    </van-popup>
</template>
<script>
export default {
  data () {
    return {
      showTipsDialog: true
    }
  },
  methods: {
    handleClose () {
      this.showTipsDialog = false
    },
    openTipsDialog () {
      this.showTipsDialog = true
    },
  }
}
</script>
<style lang="less" scoped>
.pup {
  padding: 0 20px;
  .title {
    width: 90%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #d8d8d8;
    font-size: 20px;
  }
  .content{
    margin-top: 80px;
    font-size: 14px;
    position: relative;
    p{
      text-decoration: underline!important;
      text-decoration-color: #0658F9!important;
      line-height: 28px;
    }
    .last{
      position: absolute;
      margin-top: 180px;
      font-size: 14px;
      color: #999;
    }
  }
}
</style>