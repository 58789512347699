<template>
   <!-- 新手指南左侧 -->
  <div class="side-bar">
   <ul>
    <li v-for="(item, index) in tabList" :key="index" @click="goDetail(item)"
    :class="currentId == item.id ? 'active': ''">
      {{ item.name }}
    </li>
   </ul>
  </div>
</template>
<script>
export default {
  data () {
    return {
      tabList: [
        {
          id: 1,
          name:'01：使用说明',
          path: '/guide-ai'
        },
        {
          id: 2,
          name:'02：功能介绍',
          path: '/guide-apis'
        },
        // {
        //   id: 3,
        //   name:'03：小风车消耗',
        //   path:'/guide-windmill'
        // },
        // {
        //   id: 4,
        //   name:'04：邀请有礼',
        //   path: '/guide-invite'
        // },
        // {
        //   id: 5,
        //   name:'05：社群交流',
        //   path: '/guide-contact'
        // },
      ],
      currentId:'',
      pathArr:['/guide-ai','/guide-apis','/guide-windmill','/guide-invite','/guide-contact'],
      tabObj: {
        '/guide-ai':1,
        '/guide-apis':2,
        '/guide-windmill':3,
        '/guide-invite': 4,
        '/guide-contact':5
      }
    }
  },
  created () {
    let pathStr = this.$route.path
    this.currentId = this.tabObj[pathStr]
  },
  // watch: {
  //   '$route.path': {
  //     handler(val) {
  //       console.log(val, 'val')

  //     }
  //   }
  // },
  methods: {
    goDetail (item) {
      this.currentId = item.id
      this.$router.push({path: item.path})
    },
  }
}
</script>
<style lang="less" scoped>
.side-bar{
  min-height: 100vh;
  background: #fff;
  width: 186px;
  background-color: #fff;
  ul{
    margin-left: 15px;
    font-size: 12px;
    color: #3D3D3D;
    li{
      margin-top: 36px;
      cursor: pointer;
      padding: 5px;
      // padding-right: 0;
      width: 148px;
    }
    .active{
      background: #EBF6E9;
      color: @themecolor;
    }
  }
}
</style>