<template>
  <div class="pup">
    <van-popup
      v-model="diaValue"
      class="pup"
      round
      :close-on-click-overlay="true"
      :closeable="true"
      @click-overlay="close"
      @click-close-icon="close"
      :style="{ height: '360px', width: setwidth }"
    >
      <p class="title">通知</p>
      <div class="prompt_wrap">
        <div class="conntent">
          <br />
          <p>Hi，你终于来啦!</p>
          <br />
          <br />
          <p>我是锅圈AI助手，欢迎来到智能创作新纪元!</p>
          <br />
          <p>在这里，您将踏上一段充满想象与创新的创作之旅。我们已经为您准备好了强大的AI工具和资源，让您的创作潜能得到最大限度的发挥。
          </p>
          <br />
          <p>准备好了吗？让我们一起开启这段令人激动的创作历程吧！</p>
          <br />
          <p>我已经迫不及待想要成为您的得力助手，为您提供全方位的支持和辅助。期待与您共同探索创作的无限可能！</p>
          <!-- <p>
            快看看你的账户，是不是增加了20次使用次数？这是新注册用户才的专属福利喔~
          </p>
          <br />
          <p>别忘了每天都来和我互动呀~</p>
          <p>每日首次登录再送2次使用次数!</p>
          <br />
          <p>如果想获取更多免费试用次数~可以通过分享、推荐好友注册实现!</p>
          <p>(详情可点击网站相关页面查看)</p>
          <br /> -->
          <!-- <p>你准备好开始创作了吗？我已经迫不及待为你打辅助啦！</p> -->
        </div>
        <!-- <div class="btns" @click="close">关闭</div> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    diaValue: {
      get() {
        return this.value
      },
      set() {},
    },
    // 不支持响应式  得刷新页面生效
    setwidth() {
      if (document.body.offsetWidth >= 992) {
        return '500px'
      } else {
        return '330px'
      }
    },
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="less" scoped>
// .conntent::-webkit-scrollbar {
//   display: none;
// }
.pup {
  .title {
    width: 100%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
  }
  /deep/ .van-popup {
    background-color: #fff;
  }
  .prompt_wrap {
    width: 100%;
    // height: 240px;
    box-sizing: border-box;
    margin-top: 50px;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    color: #3d3d3d;
    font-size: 14px;
    .conntent {
      height: 270px;
      overflow-y: auto;
    }
    .btns {
      color: #fff;
      cursor: pointer;
      max-width: 332px;
      height: 40px;
      background: linear-gradient(135deg, #416fff 0%, #00ced1 100%);
      border-radius: 12px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  // pc
  @media screen and (min-width: 993px) {
    .title {
      font-size: 20px;
    }
    .btns {
      margin-top: 30px !important;
    }
  }

  // 手机
  @media screen and (min-width: 0px) and (max-width: 992px) {
    .conntent {
      height: 260px;
      overflow-y: auto;
      margin-top: 10px;
    }
    .prompt_wrap {
      padding-top: 10px;
    }
    .title {
      font-size: 20px;
    }
    .btns {
      margin-top: 15px !important;
    }
  }
}
</style>

