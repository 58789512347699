import Vue from 'vue'
import VueRouter from 'vue-router'
import PcLayout from "@/views/components/pc-layout.vue";
import pcGuide from "@/views/components/pc-guide.vue";

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// 针对 push 方法
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// 针对 replace 方法
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mainView',
    component: PcLayout,
    redirect: '/home',
    children: [
      {
        path: '/homeIndex',
        name: 'homeIndex',
        redirect: '/home',
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home.vue'),
        meta: {
          showService: true
        }
      },
      {
        path: '/ai-design',
        name: 'ai-design',
        component: () => import('../views/ai-design/index.vue'),
        meta: {
          showService: true
        }
      },
      {
        path: '/sketch-to-image',
        name: 'sketchToImage',
        meta: {
          showLeft: false,
          showFooter: false
        },
        component: () => import('../views/ai-design/sketch-to-image.vue'),
      },
      {
        path: '/image-upscaler',
        name: 'imageUpscaler',
        meta: {
          showLeft: false,
          showFooter: false
        },
        component: () => import('../views/ai-design/image-upscaler.vue'),
      },
      {
        path: '/background-bokeh',
        name: 'background-bokeh',
        meta: {
          showLeft: false,
          showFooter: false
        },
        component: () => import('../views/ai-design/background-bokeh.vue'),
      },
      {
        path: '/ai-imitate-image',
        name: 'ai-imitate-image',
        meta: {
          showLeft: false,
          showFooter: false
        },
        component: () => import('../views/ai-design/ai-imitate-image.vue'),
      },
      {
        path: '/replace-background',
        name: 'replace-background',
        meta: {
          showLeft: false,
          showFooter: false
        },
        component: () => import('../views/ai-design/replace-background.vue'),
      },
      {
        path: '/ai-matting',
        name: 'ai-matting',
        meta: {
          showLeft: false,
          showFooter: false
        },
        component: () => import('../views/ai-design/ai-matting.vue'),
      },
      {
        path: '/ai-inpainting',
        name: 'ai-inpainting',
        meta: {
          showLeft: false,
          showFooter: false
        },
        component: () => import('../views/ai-design/ai-inpainting.vue'),
      },
      {
        path: '/ai-illustration',
        name: 'ai-illustration',
        meta: {
          showLeft: false,
          showFooter: false
        },
        component: () => import('../views/ai-design/ai-illustration.vue'),
      },
      {
        path: '/editor',
        name: 'editor',
        meta: {
          showLeft: false,
          showFooter: false,
        },
        component: () => import('../views/editor.vue'),
      },
      {
        path: '/chat',
        name: 'chat',
        meta: {
          showLeft: false,
          showFooter: false,
        },
        component: () => import('../views/chat.vue'),
      },
      {
        path: '/chathisory',
        name: 'chathisory',
        meta: {
          showLeft: false,
          showFooter: false,
        },
        component: () => import('../views/chat.vue'),
      },
      {
        path: '/createnum',
        name: 'createnum',
        meta: {
          showLeft: false
        },
        component: () => import('../views/createnum.vue'),
      },
      {
        path: '/ai-audio',
        name: 'ai-audio',
        meta: {
          showFooter: false,
          showService: true
        },
        component: () => import('../views/ai-audio.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/share.vue'),
  },
  {
    path: '/guide',
    name: 'guide',
    component: pcGuide,
    children: [
      {
        path: '/guide',
        name: 'guide',
        component: () => import('../views/guidepage/index.vue'),
       },
       {
        path: '/guide-ai',
        name: 'guideAi',
        component: () => import('../views/guidepage/guide-ai.vue'),
       },
       {
        path: '/guide-apis',
        name: 'guideApis',
        component: () => import('../views/guidepage/guide-apis.vue'),
       },
       {
        path: '/guide-windmill',
        name: 'guideWindmill',
        component: () => import('../views/guidepage/guide-windmill.vue'),
       },
       {
        path: '/guide-contact',
        name: 'guide-contact',
        component: () => import('../views/guidepage/guide-contact.vue'),
       },
       {
        path: '/guide-invite',
        name: 'guide-invite',
        component: () => import('../views/guidepage/guide-invite.vue'),
       }
     ],
  },
]

const router = new VueRouter({
  routes,
})

export default router

